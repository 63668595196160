import {RootStepActions} from "../actions/RootStepActions";
import {IStepType} from "../model/IStepType";
import IRequestedTokensResponse, {defaultRequestedTokensResponse} from "../model/IRequestedTokensResponse";
import IPayload from "../model/IPayload";
import ISchema from "../model/ISchema";
import IStepRequest from "../model/IStepRequest";
import ClientStep from "../client/ClientStep";

type RootStepState = {
	connection: any,
	payload: IPayload | null,
	schema: Map<string, ISchema>
	journeySchema: Map<string, ISchema>
	currentStep: IStepType,
	tokens: IRequestedTokensResponse,
	isLoading: boolean,
}

const initialState: RootStepState = {
	connection: null,
	payload: null,
	schema: new Map<string, ISchema>(),
	journeySchema: new Map<string, ISchema>(),
	currentStep: IStepType.ClientStep,
	tokens: defaultRequestedTokensResponse,
	isLoading: true,
}

const RootStepReducer = (state: RootStepState = initialState, action: RootStepActions): RootStepState => {
	switch (action.type) {
		case "CREATE_CONNECTION":
			return {
				...state,
				connection: action.payload
			}
		case "SET_PAYLOAD":
			return {
				...state,
				payload: action.payload
			}
		case "SET_SCHEMA":
			return {
				...state,
				schema: action.payload
			}
		case "SET_JOURNEY_SCHEMA":
			return {
				...state,
				journeySchema: action.payload
			}
		case "ADD_PAYLOAD_ARGUMENTS":
			if(state.payload?.arguments?.execute) {
				return {
					...state,
					payload: {
						...state.payload,
						arguments: {
							...state.payload.arguments,
							execute: {
								...state.payload.arguments.execute,
								inArguments: [
									{
										...state.payload.arguments.execute.inArguments[0],
										...action.payload,
									}
								]
							}
						}
					}
				}
			}
			return state
		case "SET_PAYLOAD_CONFIGURED":
			if(state.payload?.metaData) {
				return {
					...state,
					payload: {
						...state.payload,
						metaData: {
							...state.payload.metaData,
							isConfigured: true
						}
					}
				}
			}
			return state;
		case "SET_TOKENS":
			return {
				...state,
				tokens: action.payload
			}
		case "GO_TO_NEXT_STEP":
			return {
				...state,
				currentStep: getNextStep(state.currentStep, state)
			}
		case "GO_TO_PREV_STEP":
			return {
				...state,
				currentStep: getPrevStep(state.currentStep)
			}
		case "GO_TO_STEP":
			return {
				...state,
				currentStep: getStep(action.payload, state.currentStep)
			}
		case "START_LOADING":
			return {
				...state,
				isLoading: true,
			}
		case "END_LOADING":
			return {
				...state,
				isLoading: false,
			}
		default:
			return state;
	}
}

function closeModal(state: RootStepState) {
	const payload = state.payload
	state.connection.trigger("updateActivity", payload);
}

const getNextStep = (step: IStepType, state: RootStepState): IStepType => {

	switch (step) {
		case IStepType.ClientStep:
			return IStepType.MessageStep;
		case IStepType.MessageStep:
			closeModal(state);
			return step;
		default:
			return step;
	}
}

const getPrevStep = (step: IStepType): IStepType => {
	switch (step) {
		case IStepType.MessageStep:
			return IStepType.ClientStep;
		default:
			return step;
	}
}

const getStep = (getStep: IStepRequest, oldStep: IStepType): IStepType => {
	switch (getStep.key) {
		case "ClientStep":
			return IStepType.ClientStep;
		case "MessageStep":
			return IStepType.MessageStep;
		default:
			return oldStep;
	}
}

export default RootStepReducer;
