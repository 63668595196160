import React from 'react';
import './App.css';
import AppRoutes from "./app/routes";

const App: React.FC = () => <>
    <div className={'content-header'}>
        <AppRoutes/>
    </div>
</>

export default App;