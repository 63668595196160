import {ClientStepActions} from "../actions/ClientStepActions";
import IClient, {defaultClient} from "../model/IClient";

type ClientStepState = {
	client: IClient,
}

const initialState: ClientStepState = {
	client: defaultClient,
}

const ClientStepReducer = (state: ClientStepState = initialState, action: ClientStepActions) => {
	switch (action.type) {
		case "SET_CLIENT":
			return {
				...state,
				client: action.payload
			}
		default:
			return state;
	}
}

export default ClientStepReducer;
