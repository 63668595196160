import React, {Dispatch, PropsWithChildren, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../app/store/root-reducer";
import * as Yup from "yup";
import {SchemaOf} from "yup";
import {ErrorMessage, Field, Form, FormikHelpers, FormikProvider, useFormik} from "formik";
import {Button, FormControlLabel} from "@mui/material";
import {Row} from "reactstrap";
import {MessageStepActions} from "./actions/MessageStepActions";
import IMessage, {defaultMessage} from "./model/IMessage";
import {TreeItem, TreeView} from "@mui/lab";
import {ChevronRight, ExpandMore} from "@mui/icons-material";
import ISchema, {ISchemaType} from "../model/ISchema";
import {FormikProps} from "formik/dist/types";
import {goToNextStep, goToPrevStep} from "../step-management";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

const MessageStep: React.FC = (props: PropsWithChildren<any>) => {
	const {schema, journeySchema, payload} = useSelector((state: AppState) => state.rootStep);
	const fullSchema = new Map<string, ISchema>([
		...schema,
		...journeySchema,
	])
	const {message} = useSelector((state: AppState) => state.messageStep);
	const dispatcher: Dispatch<any> = useDispatch()

	const MessageStepSchema: SchemaOf<IMessage> = Yup.object().shape({
		message: Yup.string()
			.required('Message is required!'),
		shortLink: Yup.bool().required("Short Link is required"),
	});

	const onSubmit = async (value: IMessage, actions: FormikHelpers<IMessage>) => {
		await dispatcher((dispatch: Dispatch<MessageStepActions>) => {
			value.message = replaceUnicodeWithWhiteCharacters(value.message)
			dispatch({type: "SET_MESSAGE", payload: value})
		})
		dispatcher(goToNextStep)
	}

	const [showWhiteCharacters, setShowWhiteCharacters] = useState<boolean>(false);
	const [schemaItemsKeys, setSchemaItemsKeys] = useState<Array<string>>([]);
	const [invalidSchemaItemsKeys, setInvalidSchemaItemsKeys] = useState<Array<string>>([]);

	const formikRef: React.Ref<FormikProps<IMessage>> = React.useRef(null);
	const formik = useFormik<IMessage>({
		initialValues: {
			...defaultMessage,
			shortLink: message.shortLink
		},
		initialTouched: {
			shortLink: true,
			message: message.message !== ""
		},
		innerRef: formikRef,
		onSubmit: onSubmit,
		validationSchema: MessageStepSchema
	})

	const getSchemaItemKey = (schemaEntry: ISchema): string | null => {
		if (schemaEntry.type === ISchemaType.ITEM) {
      		return schemaEntry.isJourney
        		? `{{${schemaEntry.key}}}`
        		: `{{Contact.Attribute.${schemaEntry.key}}}`;
    	}
		return null;
	}

	const onItemClick = (schemaEntry: ISchema) => {
		const message = getSchemaItemKey(schemaEntry);
		if (message != null) {
			insertMessage(message);
		}
	}

	const onPreviousClick = () => {
		dispatcher(goToPrevStep);
	}

	const insertMessage = (message: string) => {
		dispatcher((dispatch: Dispatch<MessageStepActions>, getState: () => AppState) => {
			const messageForm = getState().messageStep.message
			const textField = document.getElementById("message-field")!
			// @ts-ignore
			const cursorPosition = textField.selectionStart
			// @ts-ignore
			const text = textField.value;
			let textBeforeCursorPosition = text.substring(0, cursorPosition ?? 0)
			let textAfterCursorPosition = text.substring(cursorPosition ?? 0, text.length)
			const newMessage = textBeforeCursorPosition + message + textAfterCursorPosition;
			dispatch({
				type: "SET_MESSAGE", payload: {
					shortLink: messageForm.shortLink,
					message: newMessage
				}
			})
			formik.setFieldValue("message", newMessage)
		})
	}

	const getTreeItem = (schemaEntry: ISchema) => {
		return <TreeItem key={schemaEntry.id} nodeId={schemaEntry.id} label={schemaEntry.label} onClick={(event) => {
			event.preventDefault();
			onItemClick(schemaEntry);
		}}>
			{schemaEntry.nodes
				.filter(node => node && fullSchema.has(node))
				.map(node => getTreeItem(fullSchema.get(node)!))
			}
		</TreeItem>
	}

	useEffect(() => {
		dispatcher((dispatch: Dispatch<MessageStepActions>, getState: () => AppState) => {
			const payloadArguments = payload?.arguments?.execute?.inArguments[0] as IMessage;
			if (payloadArguments?.message && payloadArguments?.shortLink !== undefined) {
				const newPayload = {
					message: payloadArguments.message,
					shortLink: payloadArguments.shortLink
				}
				dispatch({
					type: "SET_MESSAGE", payload: newPayload
				});
				formik.setValues(newPayload)
			}
		})
		formikRef?.current?.validateForm()
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			updateMessage();
		}, 500)

		return () => clearTimeout(timer)
	}, [message.message])

	const updateMessage = (newMessage: string | undefined = undefined) => {
		dispatcher((dispatch: Dispatch<MessageStepActions>, getState: () => AppState) => {
			const messageToUpdate = newMessage === undefined ?
				// @ts-ignore
				document.getElementById("message-field")!.value :
				newMessage;
			dispatch({
				type: "SET_MESSAGE", payload: {
					...message,
					message: messageToUpdate
				}
			});
		})
	}

	const replaceWhiteCharactersWithUnicode = (inputString: string): string => {
		const result = inputString.replace(/[\s\t\n]/g, (match) => {
		switch (match) {
			case " ":
				return "\u25A1"; 
			case "\t":
				return "\u21B9";
			case "\n":
				return "\u23CE";
			default:
				return match;
			}
		});

    	return result;
  	}

	const replaceUnicodeWithWhiteCharacters = (inputString: string): string => {
		const result = inputString.replace(/[\u25A1\u21B9\u23CE]/g, (match) => {
		switch (match) {
			case '\u25A1':
				return ' ';
			case '\u21B9':
				return '\t';
			case '\u23CE':
				return '\n';
			default:
				return match;
			}
  		});

    	return result;
	}

	const containsSpecialCharacters = (text: string): boolean => {
    const nonAsciiRegex = /[\u0080-\uFFFF]/;
    return nonAsciiRegex.test(text);
  }

	const handleChangeShowWhiteCharacters = (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowWhiteCharacters(event.target.checked);
    };

	const extractKeys = (inputString: string): string[] => {
		const regex = /{{(.*?)}}/g;
		const matches = [];
		let match;

		while ((match = regex.exec(inputString)) !== null) {
		matches.push(match[0]);
		}

    	return matches;
  	}

	const findInvalidSchemaItemsKeys = (text: string): void => {
		const matches = extractKeys(text);
        const invalidMatches: string[] = matches.filter(
          (match) => !schemaItemsKeys.includes(match)
        );

		setInvalidSchemaItemsKeys(invalidMatches);
	}

	useEffect(() => {
		findInvalidSchemaItemsKeys(message.message);

		if (message.message !== "") {
			if (showWhiteCharacters) {
				const result = replaceWhiteCharactersWithUnicode(message.message)
				updateMessage(result)
			} else {
				const result = replaceUnicodeWithWhiteCharacters(message.message)	
				updateMessage(result)
			};
		}
	}, [showWhiteCharacters, message.message])

  useEffect(() => {
	const itemsKeys = Array.from(fullSchema.values())
		.filter((schemaEntry) => schemaEntry.type === ISchemaType.ITEM)
		.map((item) => getSchemaItemKey(item))
		.filter((key) => key !== null)
		.map((key) => key as string); 
	
	setSchemaItemsKeys(itemsKeys)
  }, [schema, journeySchema])

	return (
    <FormikProvider value={formik}>
      <Form>
        <div className={"card-header"}>
          <h3>Step 2</h3>
        </div>
        <div className={"card-body"}>
          <Row className={"d-block form-group required"}>
            <label className={"control-label"} htmlFor="message">
              Provide Message:
            </label>
            <div className={"input-group"}>
              <div className={"input-group-prepend"}>
                <span className={"input-group-text"}>
                  <i className={"fa fa-envelope"} />
                </span>
              </div>
              <Field
                type="textarea"
                component="textarea"
                rows={30}
                id="message-field"
                className={"form-control"}
                name="message"
                onChange={(event: any) => {
                  message.message = event.target.value;
                  return formik.handleChange(event);
                }}
                onBlur={formik.handleBlur}
                placeholder={"Message"}
                value={message.message}
              />
            </div>
            <ErrorMessage name="message">
              {(msg: string) => <div className={"error-msg"}>{msg}</div>}
            </ErrorMessage>
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMore />}
              defaultExpandIcon={<ChevronRight />}
              sx={{ overflowY: "auto" }}
            >
              {Array.from(fullSchema?.values())
                .filter(
                  (schemaEntry) =>
                    schemaEntry.id && schemaEntry.type === ISchemaType.PARENT
                )
                .map((schemaEntry) => {
                  if (schemaEntry.nodes.length !== 0) {
                    return getTreeItem(schemaEntry);
                  }
                })}
            </TreeView>
          </Row>
		  {invalidSchemaItemsKeys.length !== 0 &&
          	<Row className={"d-block"}>
				<Typography gutterBottom color={"red"}>
					Bad keys: {invalidSchemaItemsKeys.join(', ')}
				</Typography>
          	</Row>
		  }
          {containsSpecialCharacters(message.message) && (
            <Row className={"d-block"}>
              <Typography gutterBottom color={"red"}>
                {showWhiteCharacters
                  ? "Non-ascii characters are used due to showing white characters!"
                  : "Non-ascii characters are used!"}
              </Typography>
            </Row>
          )}
          <Row className={"d-block"}>
            <div>
              <FormControlLabel
                label="Do you want to see white characters?"
                control={
                  <Checkbox
                    size="small"
                    checked={showWhiteCharacters}
                    onChange={handleChangeShowWhiteCharacters}
                  />
                }
              />
            </div>
          </Row>
          <Row className={"d-block"}>
            <div className={"input-group form-group form-check"}>
              <label className="form-check-label" htmlFor="shortLink">
                Do you want to shorten url?
              </label>
              <Field
                type="checkbox"
                className="form-check-input"
                name="shortLink"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ErrorMessage name="shortLink">
                {(msg: string) => <div className={"error-msg"}>{msg}</div>}
              </ErrorMessage>
            </div>
          </Row>
          <Row className={"btn-message-step-group form-group"}>
            <Button
              color="primary"
              onClick={onPreviousClick}
              id={"next-step-btn"}
            >
              Previous Step
            </Button>
            <Button
              color="success"
              id={"next-step-btn"}
              type={"submit"}
              disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}
            >
              Finish
            </Button>
          </Row>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default MessageStep
