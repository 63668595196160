import {Dispatch, PropsWithChildren, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../app/store/root-reducer";
import * as Yup from "yup";
import {SchemaOf} from "yup";
import {ErrorMessage, Form, FormikHelpers, FormikProvider, useFormik} from "formik";
import {Button} from "@mui/material";
import {Row} from "reactstrap";
import {IClientType} from "./model/IClientType";
import IClient, {defaultClient} from "./model/IClient";
import {ClientStepActions} from "./actions/ClientStepActions";
import {goToNextStep} from "../step-management";
import {IInternalArguments} from "../model/IPayload";

const ClientStep: React.FC = (props: PropsWithChildren<any>) => {
	const dispatcher: Dispatch<any> = useDispatch()

	const {payload} = useSelector((state: AppState) => state.rootStep);
	const {type} = useSelector((state: AppState) => state.clientStep.client);

	const ClientStepSchema: SchemaOf<IClient> = Yup.object().shape({
		type: Yup.mixed().required('Type is required'),
	});

	const onSubmit = async (value: IClient, actions: FormikHelpers<IClient>) => {
		await dispatcher((dispatch: Dispatch<ClientStepActions>, getState: () => AppState) => {
			dispatch({type: "SET_CLIENT", payload: value})
		});
		dispatcher(goToNextStep)
	}

	const formik = useFormik<IClient>({
		initialValues: {
			...defaultClient,
			type: type,
		},
		onSubmit: onSubmit,
		validationSchema: ClientStepSchema
	})

	useEffect(() => {
		dispatcher((dispatch: Dispatch<ClientStepActions>, getState: () => AppState) => {
			const payloadArguments = payload?.arguments?.execute?.inArguments[0] as IClient;
			if (payloadArguments?.type) {
				const newPayload = {
					type: payloadArguments.type
				}
				dispatch({type: "SET_CLIENT", payload: {type: payloadArguments.type}});
				formik.setValues(newPayload)
			}
		})
	}, [])

	const handleSelectedType = (event: React.ChangeEvent<HTMLSelectElement>) => {
		dispatcher((dispatch: Dispatch<ClientStepActions>, getState: () => AppState) => {
			dispatch({type: "SET_CLIENT", payload: {type: event.target.value as IClientType}});
			formik.handleChange(event)
		})
	}

	const hasPhoneNumber = () => {
		const payloadArguments = payload?.arguments?.execute?.inArguments[0] as IInternalArguments;
		return !!payloadArguments?.mobileNumber;
	}

	return (
		<FormikProvider value={formik}>
			{hasPhoneNumber() ?
				<></> :
				<div className={'error-msg'}>Default phone number field is not configured in data extension.</div>
			}
			<Form>
				<div className={"card-header"}>
					<h3>Step 1</h3>
				</div>
				<div className={"card-body"}>
					<Row className={'d-block form-group has-validation required'}>
						<label className={"control-label"} htmlFor="title">Select customer type:</label>
						<div className={"input-group"}>
							<div className={"input-group-prepend"}>
                                                <span className={"input-group-text"}><i
													className={"fa fa-font"}/></span>
							</div>
							<select name="type"
									onChange={handleSelectedType}
									onBlur={formik.handleBlur}
									className={"form-control"}
									key={type}
									value={type}
									placeholder={"Select type"}>
								{Object.values(IClientType).filter(value => !Number.isInteger(value)).map(value => <option key={value}
																														   value={value}>{value}</option>)}
							</select>
						</div>
						<ErrorMessage name="type">
							{(msg: string) => <div className={'error-msg'}>{msg}</div>}
						</ErrorMessage>
					</Row>
					<Row className={'btn-message-step-group form-group'}>
						<Button color="success"
								id={"next-step-btn"}
								type={"submit"}
								disabled={formik.isSubmitting || !formik.isValid}>
							Next Step
						</Button>
					</Row>
				</div>
			</Form>
		</FormikProvider>)
}

export default ClientStep
