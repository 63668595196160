import {combineReducers} from "@reduxjs/toolkit";
import RootStepReducer from "../../components/steps/reducers/RootStepReducer";
import MessageStepReducer from "../../components/steps/message/reducer/MessageStepReducer";
import ClientStepReducer from "../../components/steps/client/reducer/ClientStepReducer";

const rootReducer = combineReducers({
	rootStep: RootStepReducer,
	clientStep: ClientStepReducer,
	messageStep: MessageStepReducer,
})

export type AppState = ReturnType<typeof rootReducer>
export default rootReducer;
