import {Dispatch} from "react";
import {RootStepActions} from "./actions/RootStepActions";
import {AppState} from "../../app/store/root-reducer";
import IStepRequest from "./model/IStepRequest";
import {IStepType} from "./model/IStepType";

export const goToNextStep = async (dispatch: Dispatch<RootStepActions>, getState: () => AppState) => {
	const appState = getState()
	const connection = appState.rootStep.connection;
	await updatePayload(connection, dispatch, appState)
	if (connection) {
		await dispatch({type: "GO_TO_NEXT_STEP"})
		connection.trigger('nextStep');
		connection.trigger('ready');
	}
}

export const goToPrevStep = async (dispatch: Dispatch<RootStepActions>, getState: () => AppState) => {
	const appState = getState()
	const connection = appState.rootStep.connection;
	await updatePayload(connection, dispatch, appState);
	if (connection) {
		connection.trigger('prevStep');
		connection.trigger('ready');
	}
	dispatch({type: "GO_TO_PREV_STEP"})
}

export const goToStep = (step: IStepRequest) => async (dispatch: Dispatch<RootStepActions>, getState: () => AppState) => {
	const appState = getState()
	const connection = appState.rootStep.connection;
	await updatePayload(connection, dispatch, appState);
	dispatch({type: "GO_TO_STEP", payload: step})
}

const updatePayload = async (connection: any, dispatch: Dispatch<RootStepActions>, appState: AppState) => {
	const clientData = appState.clientStep.client;
	const currentStep = appState.rootStep.currentStep;
	const messageData = appState.messageStep.message;
	const payloadArguments = currentStep === IStepType.MessageStep ?
		messageData :
		clientData;
	await dispatch({type: "ADD_PAYLOAD_ARGUMENTS", payload: payloadArguments})
	if (currentStep === IStepType.MessageStep) {
		await dispatch({type: "SET_PAYLOAD_CONFIGURED"});
	}
}
