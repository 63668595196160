import {Dispatch} from "react";
import {RootStepActions} from "./actions/RootStepActions";
import {AppState} from "../../app/store/root-reducer";
import Postmonger from "postmonger"
import IRequestedTokensResponse from "./model/IRequestedTokensResponse";
import {getSchema} from "./sms-connection";
import {buildSchema} from "./util/journey-schema";
import ISfmcSchema from "./model/ISfmcSchema";
import IStepRequest from "./model/IStepRequest";
import {goToNextStep, goToPrevStep} from "./step-management";

export const sfmcConnect = (dispatch: Dispatch<RootStepActions>, getState: () => AppState) => {
	// @ts-ignore
	const connection = new Postmonger.Session();

	const initialize = (data: any) => {
		if (data) {
			dispatch({type: "SET_PAYLOAD", payload: data})
			connection.trigger('requestInteraction');
			connection.trigger('requestInteractionDefaults');
			connection.trigger('requestTriggerEventDefinition');
			connection.trigger('requestSchema');
		}
	}

	const onGetTokens = (tokens: IRequestedTokensResponse) => {
		dispatch({type: "SET_TOKENS", payload: tokens})
		getSchema(tokens.fuel2token)(dispatch, getState)
	}

	const onGetTriggerEventDefinition = (eventDefinition: any) => {
		const eventDefinitionKey = eventDefinition.eventDefinitionKey;
		if(eventDefinition) {
			dispatch({
				type: "ADD_PAYLOAD_ARGUMENTS", payload: {
					accountId: `{{Contact.Key}}`,
				}
			})
		}
	};

	const onGetInteractionDefaults = (interaction: any) => {
	};

	const onGetInteraction = (interaction: any) => {
		const mobileNumber = interaction.defaults.mobileNumber[0]?.replace(/['"]+/g, '');
		dispatch({
			type: "ADD_PAYLOAD_ARGUMENTS", payload: {
				email: interaction.defaults.email[0]?.replace(/['"]+/g, ''),
				mobileNumber: mobileNumber === "legacyfallback" ? "{{Contact.Attribute.CORE_Person_Account_QA_PL.PersonMobilePhone}}" : mobileNumber
			}
		})
	};

	const onClickedNext = async () => {
		await goToNextStep(dispatch, getState)
	};

	const onClickedBack = async () => {
		await goToPrevStep(dispatch, getState)
	};

	const onGotoStep = (step: IStepRequest) => {
		dispatch({type: "GO_TO_STEP", payload: step})
	};

	const onGetSchema = (schema: ISfmcSchema) => {
		dispatch({
			type: "SET_JOURNEY_SCHEMA", payload: buildSchema(schema)
		})
	};

	connection.on("initActivity", initialize);
	connection.on("requestedTokens", onGetTokens);
	connection.on('requestedTriggerEventDefinition', onGetTriggerEventDefinition);
	connection.on('requestedInteractionDefaults', onGetInteractionDefaults);
	connection.on('requestedInteraction', onGetInteraction);
	connection.on('clickedNext', onClickedNext);
	connection.on('clickedBack', onClickedBack);
	connection.on("gotoStep", onGotoStep);
	connection.on("requestedSchema", onGetSchema);
	dispatch({type: "CREATE_CONNECTION", payload: connection})
}
