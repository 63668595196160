import ISchema, {ISchemaType} from "../model/ISchema";
import ISfmcSchema, {ISfmcSchemaEntry} from "../model/ISfmcSchema";

export const buildSchema: (sfmcSchema: ISfmcSchema) => Map<string, ISchema> = (sfmcSchema: ISfmcSchema) => {
	const journeyChildNodes = getChildNodes(sfmcSchema);
	const journeyNode: ISchema = {
		id: "journey",
		label: "Journey Data",
		type: ISchemaType.PARENT,
		key: "journey",
		nodes: Array.from(journeyChildNodes.keys()),
		isJourney: true,
	}
	const journeySchema = new Map<string, ISchema>();
	journeySchema.set("journey", journeyNode);
	Array.from(journeyChildNodes.values()).forEach((node: ISchema) => journeySchema.set(node.key, node))
	return journeySchema;
}

const getChildNodes: (sfmcSchema: ISfmcSchema) => Map<string, ISchema> = (sfmcSchema: ISfmcSchema) => {
	const resultMap = new Map<string, ISchema>();
	sfmcSchema.schema.forEach((sfmcSchemaEntry: ISfmcSchemaEntry) =>
		resultMap.set(sfmcSchemaEntry.key, {
			id: sfmcSchemaEntry.key,
			label: sfmcSchemaEntry.name,
			key: sfmcSchemaEntry.key,
			type: ISchemaType.ITEM,
			nodes: [],
			isJourney: true
		})
	)
	return resultMap;
}
