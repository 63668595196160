export default interface ISchema {
	id: string;
	label: string;
	type: ISchemaType;
	key: string;
	nodes: string[];
	isJourney: boolean;
}

export enum ISchemaType {
	BRANCH = "BRANCH",
	ITEM = "ITEM",
	PARENT = "PARENT",
}
