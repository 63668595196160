import {Dispatch, PropsWithChildren, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {sfmcConnect} from "./sfmc-connection";
import {AppState} from "../../app/store/root-reducer";
import MessageStep from "./message/MessageStep";
import {IStepType} from "./model/IStepType";
import ClientStep from "./client/ClientStep";

const RootStep: React.FC = (props: PropsWithChildren<any>) => {
	const {currentStep, connection, payload, isLoading} = useSelector((state: AppState) => state.rootStep);
	const dispatcher: Dispatch<any> = useDispatch()

	const onRender = (connection: any) => {
		connection.trigger("ready");
		connection.trigger("requestTokens");
	}

	useEffect(() => {
		if (connection) {
			onRender(connection)
		} else {
			dispatcher(sfmcConnect)
		}
	}, [connection])

	useEffect(() => {
	}, [payload, currentStep, isLoading])

	if (isLoading) {
		return <></>
	}

	switch (currentStep) {
		case IStepType.ClientStep:
			return <ClientStep/>;
		case IStepType.MessageStep:
			return <MessageStep/>;
		default:
			return <ClientStep/>
	}
}

export default RootStep
