import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import NotFound from "../../components/errors/notfound";
import InternalServerError from "../../components/errors/internalserver";
import RootStep from "../../components/steps/RootStep";

export enum RootPaths {
    ROOT = '/',
    NOT_FOUND = '/not-found',
    INTERNAL_SERVER_ERROR = '/500',
}

const AppRoutes: React.FC = () =>
    <Routes>
        <Route path={RootPaths.ROOT} element={<RootStep/>}/>
        <Route path={RootPaths.NOT_FOUND} element={<NotFound/>}/>
        <Route path={RootPaths.INTERNAL_SERVER_ERROR} element={<InternalServerError/>}/>
        <Route path='*' element={<Navigate to={RootPaths.ROOT}/>}/>
    </Routes>

export default AppRoutes
