import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./root-reducer";
import thunk from "redux-thunk"

const middlewares = [thunk]

const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
	devTools: true,
})

export default store;
