import {Dispatch} from "react";
import {RootStepActions} from "./actions/RootStepActions";
import {AppState} from "../../app/store/root-reducer";
import Postmonger from "postmonger"
import IRequestedTokensResponse from "./model/IRequestedTokensResponse";
import {useAxios} from "../../utils/axios";

export const getSchema = (fuel2token: String) => (dispatch: Dispatch<RootStepActions>, getState: () => AppState) => {
	dispatch({type: "START_LOADING"})
	useAxios().get("/schema?fuel2token=" + fuel2token).then(response => {
		dispatch({type: "SET_SCHEMA", payload: new Map(Object.entries(response.data))})
		dispatch({type: "END_LOADING"})
	}).catch(error => {
		console.error(error)
	})
}
