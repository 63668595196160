import IMessage, {defaultMessage} from "../model/IMessage";
import {MessageStepActions} from "../actions/MessageStepActions";

type MessageStepState = {
	message: IMessage,
}

const initialState: MessageStepState = {
	message: defaultMessage,
}

const MessageStepReducer = (state: MessageStepState = initialState, action: MessageStepActions) => {
	switch (action.type) {
		case "SET_MESSAGE":
			return {
				...state,
				message: action.payload
			}
		default:
			return state;
	}
}

export default MessageStepReducer;
